import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Button
} from '@mui/material'

import {
  GenericRadioGroup,
  Banner,
  GenericDialog,
  PreviewDialog,
  GenericDrawer,
  GenericAccordion,
  GenericSelect
} from '../components/common'
import { Layout, WithPage } from '../components/containers'
import { emptyFn } from '../utils/helpers'

const options = [
  {
    label: 'en-US (English - United States)',
    value: 'enUS'
  },
  {
    label: 'en-GB (English - United Kingdom)',
    value: 'enGB'
  },
  {
    label: 'en-CA (English - Canada)',
    value: 'enCA'
  },
  {
    label: 'en-AU (English - Australia)',
    value: 'enAU'
  },
  {
    label: 'es (Spanish - Spain)',
    value: 'es'
  },
  {
    label: 'es-MX (Spanish - Mexico)',
    value: 'esMX'
  },
  {
    label: 'es-PR (Spanish - Puerto Rico)',
    value: 'esPR'
  }
]

const optionsSimple = [
  {
    label: 'en-US (English - United States)',
    value: 'enUS'
  },
  {
    label: 'en-GB (English - United Kingdom)',
    value: 'enGB'
  },
  {
    label: 'en-CA (English - Canada)',
    value: 'enCA'
  },
  {
    label: 'en-AU (English - Australia)',
    value: 'enAU'
  },
  {
    label: 'es (Spanish - Spain)',
    value: 'es'
  },
  {
    label: 'es-MX (Spanish - Mexico)',
    value: 'esMX'
  },
  {
    label: 'es-PR (Spanish - Puerto Rico)',
    value: 'esPR'
  }
]

const Dev = () => {
  const [radioGroupValue, setRadioGroupValue] = useState(1)
  const [openGD, setOpenGD] = useState(false)
  const [openPD, setOpenPD] = useState(false)
  const [openGDrawer, setOpenGDrawer] = useState(false)
  const [selectValue, setSelectValue] = useState([options[0].value])
  const [selectValueSimple, setSelectValueSimple] = useState(optionsSimple[0].value)

  const handleRadioGroupChange = (event) => {
    setRadioGroupValue(event.target.value)
  }

  const handleOpenGD = () => setOpenGD(!openGD)

  const handleOpenPD = () => setOpenPD(!openPD)

  const handleOpenGDrawer = () => setOpenGDrawer(!openGDrawer)

  const handleChange = ({ value }) => setSelectValue(value)

  const handleChangeSimple = ({ value }) => setSelectValueSimple(value)

  return (
    <WithPage>
      <Layout>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Banner
              iconSrc='crawl.png'
              title='Build a predictor'
              subtitle='Create and upload predictors into your apps to improve word prediction.'
              btnLabel='Build Predictor'
              onClick={emptyFn}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component='p' variant='h1'>Generic components</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component='p' variant='subtitle2'>Generic Radio Group</Typography>
            <GenericRadioGroup
              items={[
                { value: 1, name: 'Radio button active' },
                { value: 2, name: 'Radio button inactive' },
              ]}
              name='testRadioGroup'
              value={radioGroupValue}
              onChange={handleRadioGroupChange}
            />
          </Grid>
          <Grid item xs={6}>
            <GenericSelect
              onChange={handleChange}
              label={selectValue?.length
                ? `Languages (${selectValue?.length})`
                : 'All Languages'
              }
              options={options}
              value={selectValue}
              multiple
            />
          </Grid>
          <Grid item xs={6}>
            <GenericSelect
              onChange={handleChangeSimple}
              options={optionsSimple}
              value={selectValueSimple}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' onClick={handleOpenGD}>Generic Dialog</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' onClick={handleOpenPD}>Preview Dialog</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' onClick={handleOpenGDrawer}>Generic Drawer</Button>
          </Grid>
          <Grid item xs={12}>
            <GenericAccordion
              indeterminate
              title='How can this tool help me?'
              detailsText={`The Word Prediction Pipeline helps language specialists crawl the web for data, parse data 
            and produce word predictors in various languages. Word predictors can be modified, downloaded and imported 
            into other tools.`}
              handleCheckbox={emptyFn}
            />
          </Grid>
        </Grid>
        <GenericDialog
          open={openGD}
          onClose={handleOpenGD}
          title='Cancel Predictor?'
          text={`Cancelling will discard all progress for this task.
         The predictor will not be saved. Cancel task?`}
          cancelLabel='Cancel'
          submitLabel='Keep'
          onCancel={handleOpenGD}
          onSubmit={emptyFn}
        />
        <PreviewDialog
          open={openPD}
          onClose={handleOpenPD}
          title='Allowed'
          contentString={`chabacano nopal eureka popote lorem ipsum dolor sit amet consectetur
         adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua 
         ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip 
         ex ea commodo consequat duis aute irure dolor in reprehenderit in voluptate velit 
         esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat non 
         proident sunt in culpa qui officia deserunt mollit anim id est laborum sed ut 
         perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium 
         totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae 
         vitae dicta sunt explicabo nemo enim ipsam voluptatem quia voluptas sit aspernatur aut 
         odit aut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi 
         nesciunt neque porro quisquam est qui dolorem ipsum quia dolor sit amet consectetur 
         adipisci velit sed quia non numquam eius modi tempora incidunt ut labore et dolore 
         magnam aliquam quaerat voluptatem ut enim ad minima veniam quis nostrum exercitationem 
         ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
         totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae 
         vitae dicta sunt explicabo nemo enim ipsam voluptatem quia voluptas sit aspernatur aut 
         odit aut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi 
         nesciunt neque porro quisquam est qui dolorem ipsum quia dolor sit amet consectetur 
         adipisci velit sed quia non numquam eius modi tempora incidunt ut labore et dolore 
         magnam aliquam quaerat voluptatem ut enim ad minima veniam quis nostrum exercitationem 
         ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
         adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua 
         ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip 
         ex ea commodo consequat duis aute irure dolor in reprehenderit in voluptate velit 
         esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat non 
         proident sunt in culpa qui officia deserunt mollit anim id est laborum sed ut 
         perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium 
         totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae 
         vitae dicta sunt explicabo nemo enim ipsam voluptatem quia voluptas sit aspernatur aut 
         odit aut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi 
         nesciunt neque porro quisquam est qui dolorem ipsum quia dolor sit amet consectetur 
         adipisci velit sed quia non numquam eius modi tempora incidunt ut labore et dolore 
         magnam aliquam quaerat voluptatem ut enim ad minima veniam quis nostrum exercitationem 
         ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
         totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae 
         vitae dicta sunt explicabo nemo enim ipsam voluptatem quia voluptas sit aspernatur aut 
         odit aut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi 
         nesciunt neque porro quisquam est qui dolorem ipsum quia dolor sit amet consectetur 
         adipisci velit sed quia non numquam eius modi tempora incidunt ut labore et dolore 
         magnam aliquam quaerat voluptatem ut enim ad minima veniam quis nostrum exercitationem 
         ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? 
         Quis autem vel eum iure reprehenderit qui `}
        />
        <GenericDrawer
          open={openGDrawer}
          onClose={handleOpenGDrawer}
          title='Filters'
          onSubmit={emptyFn}
          onCancel={emptyFn}
        >
          <p>djashdkajs</p>
        </GenericDrawer>
      </Layout>
    </WithPage>
  )
}

export default Dev